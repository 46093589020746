.button-checkbox {
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    
    .check {
        margin: 0;
    }
}