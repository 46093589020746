.pagination {
    width: 100%;
    margin: 15px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    p {
        color: #929292;
        font-size: 12px;
        font-weight: 500;
        text-align: center;
    }

    ul {
        list-style: none;
        display: flex;
        flex-direction: row;

        li {
            margin: 0;
            padding: 0;

            button {
                border: none;
                background-color: transparent;
                padding: 5px;
                margin: 10px 4px;
                border-radius: 3px;
                color: #696969;
                font-size: 12px;
                font-weight: 500;
                
                &:disabled {
                    opacity: .3;
                }

                &.page {
                    padding-left: 8px;
                    padding-right: 8px;

                    &.active {
                        background-color: #FFF;
                        border: solid 1px #CCC;
                    }
                }
            }
        }
    }
}