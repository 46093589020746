#auth {
    h1 {
        text-align: left;
        width: 100%;
        margin-bottom: 15px;
        font-weight: 400;
        font-size: 20px;
        color: $primary-color;
    }

    p {
        width: 100%;
        text-align: left;
        font-size: 14px;
        color: #999;
        margin-bottom: 35px;
    }
}