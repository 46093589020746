.input-group {
    position: relative;
    width: 100%;
    margin-bottom: $input-margin-bottom;
    margin-top: 5px;

    .desc {
        font-size: 12px;
        color: rgb(127, 127, 127);
    }

    .input-file-content {
        display: flex;
        align-items: center;
        width: 100%;
        height: 45px;
        border-radius: 3px;
        border: solid $input-border-width $input-label-color;
        padding: 0 15px;

        input {
            display: none;
        }

        button {
            display: flex;
            border: none;
            background-color: transparent;
            width: 100%;
            height: 100%;
            align-items: center;
            img {
                padding-right: 15px;
            }
            
            &.loading img {
                width: 50px;
                margin: -8px;
            }

            color: $primary-color;

            font-weight: 500;
            font-size: 14px;
        }
    }

    label {
        overflow: hidden;
        white-space: nowrap; /* Don't forget this one */
        text-overflow: ellipsis;
        max-width: 88%;
        font-size: 13px;
        font-weight: $input-label-weight;
        color: $input-label-color;
        position: absolute;
        left: 10px;
        background-color: #fff;
        padding: 0 10px;
        top: -8px;
    }
}