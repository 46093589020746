.table {
    width: 100%;
    border-collapse: collapse;
    border: none;

    tr {
        &.tr-disable {
            opacity: 0.5;
        }
        td, th {
            font-size: 14px;
            border: 1px solid #E3E3E3;
            padding: 0.5rem;
            text-align: left;
            max-width: 140px;
            overflow: hidden;
            text-overflow: ellipsis;

            &:first-child {
                border-left: none;
            }

            &:last-child {
                border-right: none;
            }

            &.has-link {
                cursor: pointer;
            }

            &.td-btn {
                overflow: visible;
            }
        }

        td > img {
            height: 35px;
            width: 35px;
            object-fit: contain;
        }

        th {
            color: $text-secondary-color;
            font-weight: 500;
        }

        td.no-data {
            color: $text-secondary-color;
        }

        &:last-child {
            td {
                border-bottom: none;
            }
        }

        &:first-child {
            td {
                border-top: none;
            }
            th {
                border-top: none;
            }
        }
    }
}

.data-alert {
    background-color: red;
    color: white;
    padding: 2px 5px;
    font-size: 10px;
    border-radius: 15px;
    margin-left: 5px;
    text-transform: uppercase;
}