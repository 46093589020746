.tinymce-container {
    position: relative;
    margin-bottom: 25px;

    label {
        font-size: 13px;
        font-weight: $input-label-weight;
        color: $input-label-color;
        position: absolute;
        left: 10px;
        background-color: #fff;
        padding: 0 10px;
        top: -8px;
        z-index: 1;
    }

    .tox-tinymce {
        border: 1px solid #696969;
        padding-top: 10px;
        margin-top: 5px;
        border-radius: 3px;
    }
}