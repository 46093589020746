.input-editor-group {
    position: relative;
    width: 100%;
    margin-bottom: $input-margin-bottom;

    .header {
        display: flex;
        height: 50px;
        align-items: center;
        border-radius: 3px 3px 0 0;
        border: solid 1px #696969;
        background-color: $bg-page;

        > button {
            background-color: transparent;
            border: none;
            padding: 15px;
            font-weight: 900;
        }
        .italic {
            font-weight: 900;
            font-style: italic;
        }
        .underline {
            font-weight: 900;
            text-decoration: underline;
        }
    }

    .body {
        border-radius: 0 0 3px 3px;
        border: solid 1px #696969;
        border-top: 0;
        padding: 10px;
        height: calc($input-boxes-height - 50px);
        overflow-y: auto;
        font-size: 14px;
        font-family: "Roboto", sans-serif;
    }
}