.input-checkboxes-group {
    position: relative;
    width: 100%;
    margin-bottom: $input-margin-bottom;
    

    &.bordered {
        height: $input-boxes-height;
        border: solid $input-border-width $input-label-color;
        border-radius: $border-radius-default;
        padding-bottom: 10px;
        overflow-y: auto;

        .options .option {
            padding-left: 10px;
        }
    }

    .options {
        border-radius: 3px;
        display: flex;
        flex-direction: column;

        .option {
            width: 100%;
            border: none;
            padding: 0;
            background-color: transparent;
            margin-top: 10px;
            display: flex;
            align-items: center;
        }
    }
}