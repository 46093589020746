.permission-group {
    padding-bottom: 15px;

    h2 {
        margin-bottom: 10px;
    }

    button {
        border: none;
        background: transparent;
        display: flex;
        padding-bottom: 5px;
    }
}