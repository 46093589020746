@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;900&display=swap');

* { 
    margin: 0; 
    padding: 0; 
    outline: 0; 
    -webkit-box-sizing: border-box; 
    -moz-box-sizing: border-box; 
    box-sizing: border-box;
}

body {
    font-family: "Roboto", sans-serif;
    min-width: 1200px;
}

a {
    text-decoration: none;
}

a.disabled {
    pointer-events: none;
    opacity: .2;
}

button {
    font-family: "Roboto", sans-serif;
    cursor: pointer;
}

.filter-tab {
    display: flex;
    list-style: none;
    li {
        margin-right: 25px;
        margin-top: 0px;
        margin-bottom: 15px;
        a {
            &.active {
                font-weight: 700;
            }
            font-size: 14px;
            text-decoration: underline;
        }
    }
}

.section-title {
    width: 100%;
    font-weight: 500;
    font-size: 16px;
    color: $text-secondary-color;
    margin: 15px 0;
}

/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #dadada; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
    background: rgb(153, 153, 153); 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555; 
}

@-moz-keyframes spin { 
    100% { -moz-transform: rotate(360deg); } 
}
@-webkit-keyframes spin { 
    100% { -webkit-transform: rotate(360deg); } 
}
@keyframes spin { 
    100% { 
        -webkit-transform: rotate(360deg); 
        transform:rotate(360deg); 
    } 
}