.input-textarea-group {
    position: relative;
    width: 100%;
    margin-bottom: $input-margin-bottom;
    margin-top: 5px;

    &.full-height {
        height: calc(100% - $input-margin-bottom - 5px);
    }

    
    textarea {
        font-family: "Roboto", sans-serif;
        display: flex;
        align-items: center;
        width: 100%;
        border-radius: 3px;
        border: solid $input-border-width $input-label-color;
        padding: 14px;
        height: 100%;

        &:disabled {
            color: #949494;
        }
    }

    label {
        font-size: 13px;
        font-weight: $input-label-weight;
        color: $input-label-color;
        position: absolute;
        left: 10px;
        background-color: #fff;
        padding: 0 10px;
        top: -8px;
    }
}